// MessageComponent.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import CodeBlock from './CodeBlock';
import { useDeckContext } from './DeckContext';
import './MessageComponent.css';

const MessageComponent = ({ msg, index }) => {
    const {
        combinedCards,
        currentCardIndex,
        editedFrontLatexContent,
        editedBackLatexContent,
        setEditedFrontLatexContent,
        setEditedBackLatexContent,
        setTempCard,
        setCurrentCardIndex
    } = useDeckContext();

    if (msg.role === "user") {
        return <div className={`message ${msg.role}`}>{msg.content}</div>;
    }

    const parseResponse = (text) => {
        const latexRegex = /\[latex\](.*?)\[\/latex\]/gs;
        let splitText = text.split(latexRegex);

        if (splitText.length < 4) {
            return { textBeforeFront: "", frontLatexContent: "", textBetweenFrontBack: "", backLatexContent: "", textAfterBack: "" };
        }

        return {
            textBeforeFront: splitText[0].trim(),
            frontLatexContent: splitText[1].trim(),
            textBetweenFrontBack: splitText[2].trim(),
            backLatexContent: splitText[3].trim(),
            textAfterBack: splitText[4] ? splitText[4].trim() : "",
        };
    };

    const updateTempCard = (field, content) => {
        if (currentCardIndex === 0) {
            const updatedMessages = combinedCards[currentCardIndex].messages.map((message, index) => {
                if (index === combinedCards[currentCardIndex].messages.length - 1) {
                    const parsedResponse = parseResponse(message.content);
                    const updatedContent = field === 'front'
                        ? `${parsedResponse.textBeforeFront}[latex]${content || ''}[/latex]${parsedResponse.textBetweenFrontBack}[latex]${parsedResponse.backLatexContent || ''}[/latex]${parsedResponse.textAfterBack}`
                        : `${parsedResponse.textBeforeFront}[latex]${parsedResponse.frontLatexContent || ''}[/latex]${parsedResponse.textBetweenFrontBack}[latex]${content || ''}[/latex]${parsedResponse.textAfterBack}`;

                    return { ...message, content: updatedContent };
                }
                return message;
            });

            setTempCard(prevCard => ({
                ...prevCard,
                _id: combinedCards[currentCardIndex]._id === 'c-temp' ? 'temp' : combinedCards[currentCardIndex]._id,
                front: field === 'front' ? content : prevCard.front,
                back: field === 'back' ? content : prevCard.back,
                messages: updatedMessages,
                paramSearch: {
                    front: field === 'front' 
                        ? { fontSize: null, cardWidth: null }
                        : prevCard.paramSearch.front,
                    back: field === 'back'
                        ? { fontSize: null, cardWidth: null }
                        : prevCard.paramSearch.back
                }
            }));
        } else {
            const updatedMessages = combinedCards[currentCardIndex].messages.map((message, index) => {
                if (index === combinedCards[currentCardIndex].messages.length - 1) {
                    const parsedResponse = parseResponse(message.content);
                    const updatedContent = field === 'front'
                        ? `${parsedResponse.textBeforeFront}[latex]${content || ''}[/latex]${parsedResponse.textBetweenFrontBack}[latex]${parsedResponse.backLatexContent || ''}[/latex]${parsedResponse.textAfterBack}`
                        : `${parsedResponse.textBeforeFront}[latex]${parsedResponse.frontLatexContent || ''}[/latex]${parsedResponse.textBetweenFrontBack}[latex]${content || ''}[/latex]${parsedResponse.textAfterBack}`;

                    return { ...message, content: updatedContent };
                }
                return message;
            });

            const updatedCard = {
                ...combinedCards[currentCardIndex],
                _id: combinedCards[currentCardIndex]._id === 'c-temp' ? 'temp' : combinedCards[currentCardIndex]._id,
                front: field === 'front' ? content : combinedCards[currentCardIndex].front,
                back: field === 'back' ? content : combinedCards[currentCardIndex].back,
                messages: updatedMessages,
                paramSearch: {
                    front: field === 'front' 
                        ? { fontSize: null, cardWidth: null }
                        : combinedCards[currentCardIndex].paramSearch.front,
                    back: field === 'back'
                        ? { fontSize: null, cardWidth: null }
                        : combinedCards[currentCardIndex].paramSearch.back
                }
            };

            setTempCard(updatedCard);
            setCurrentCardIndex(0);
        }
    };

    const { textBeforeFront, frontLatexContent, textBetweenFrontBack, backLatexContent, textAfterBack } = parseResponse(msg.content);
    const isLastMessage = index === combinedCards[currentCardIndex].messages.length - 1;

    return (
        <div className={`message ${msg.role}`}>
            <ReactMarkdown className="react-markdown">{textBeforeFront}</ReactMarkdown>
            {(frontLatexContent || editedFrontLatexContent !== null) && (
                <CodeBlock
                    key={`front-${combinedCards[currentCardIndex]._id}`}
                    language="latex"
                    onChange={(content) => {
                        setEditedFrontLatexContent(content);
                        updateTempCard('front', content);
                    }}
                    readOnly={!isLastMessage}
                >
                    {editedFrontLatexContent !== null ? editedFrontLatexContent : frontLatexContent}
                </CodeBlock>
            )}
            <ReactMarkdown className="react-markdown">{textBetweenFrontBack}</ReactMarkdown>
            {(backLatexContent || editedBackLatexContent !== null) && (
                <CodeBlock
                    key={`back-${combinedCards[currentCardIndex]._id}`}
                    language="latex"
                    onChange={(content) => {
                        setEditedBackLatexContent(content);
                        updateTempCard('back', content);
                    }}
                    readOnly={!isLastMessage}
                >
                    {editedBackLatexContent !== null ? editedBackLatexContent : backLatexContent}
                </CodeBlock>
            )}
            <ReactMarkdown className="react-markdown">{textAfterBack}</ReactMarkdown>
        </div>
    );
};

export default MessageComponent;