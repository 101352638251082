import { useCallback } from 'react';

/* let timed = (f) => (...args) => {
  let start = performance.now();
  let ret = f(...args);
  console.log(`${f.name}() took ${(performance.now() - start).toFixed(3)}ms`);
  return ret;
} */

function createKatexHash(katexElement) {
  // Get the text content
  const textContent = katexElement.textContent;

  // Create a simplified representation of the structure
  const structureRepresentation = Array.from(katexElement.querySelectorAll('*'))
    .map(el => el.tagName)
    .join('');

  // Combine text and structure
  const combinedString = textContent + structureRepresentation;

  // Create a hash
  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    const char = combinedString.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  return hash.toString(36); // Convert to base 36 for shorter string
}

// This function does not scale well with content length
let extractValuesFromRefs = (contentRef, cardRef) => {
  if (!contentRef.current || !cardRef.current) return null;

  // Use getBoundingClientRect() to get all dimensions at once, minimizing reflows
  //    THESE ARE THE PROBLEM LINES, MEMOIZE PLEASE
  const contentRect = contentRef.current.getBoundingClientRect();
  const cardRect = cardRef.current.getBoundingClientRect();

  const katexElement = contentRef.current.querySelector('.katex');
  if (!katexElement) return null;
  //console.log(katexElement.textContent);
  return {
    katexElement,
    contentScrollWidth: contentRect.width,
    contentScrollHeight: contentRect.height,
    cardClientWidth: cardRect.width,
    cardClientHeight: cardRect.height,

  };
};
//extractValuesFromRefs = timed(extractValuesFromRefs);

let contentFits = (fontSize, values) => {
  if (!values) return false;

  const { katexElement, cardClientHeight } = values;

  katexElement.style.fontSize = `${fontSize}px`;

  // Force a reflow
  void katexElement.offsetHeight;

  const scrollHeight = katexElement.scrollHeight;
  const fits = scrollHeight <= (cardClientHeight*0.90);

  return fits;
};
//contentFits = timed(contentFits);

let findOptimalFontSize = (values, face, cardId) => {
  if (!values) return 0.1; // Default minimal font size

  let low = 0.1;
  let high = 1000;
  const threshold = 0.05;
  const maxIterations = 100;
  let iterations = 0;

  //console.time(`binarySearch`);
  while (high - low >= threshold && iterations < maxIterations) {
    const mid = (low + high) / 2;

    if (contentFits(mid, values)) {
      low = mid;
    } else {
      high = mid;
    }

    iterations++;
  }
  //console.timeEnd(`binarySearch`);

  let optimalSize = low*0.90; // adjust down by 10% for safety margin

  const { katexElement } = values;
  katexElement.style.fontSize = ``;
  document.documentElement.style.setProperty(`--${face}-font-size`, `${optimalSize}px`);

  return optimalSize;
};
//findOptimalFontSize = timed(findOptimalFontSize);

let memoizedFindOptimalFontSize = (() => {
  const cache = new Map();

  return (contentRef, cardRef, face, cardId) => {
    let optimalFontSize = 0.1; // Default minimal font size

    const values = extractValuesFromRefs(contentRef, cardRef); // is it this?
    if (!values) return optimalFontSize;

    const { katexElement, contentScrollWidth, contentScrollHeight, cardClientWidth, cardClientHeight } = values;
    const cacheKey = `${createKatexHash(katexElement)}-${cardId}-${face}-${contentScrollWidth}-${contentScrollHeight}-${cardClientWidth}-${cardClientHeight}`;
    //console.log(cacheKey);
    
    // The cache lookup has been cleared of inefficiencies
    if (cache.has(cacheKey)) {
      //console.log(`memoizedFindOptimalFontSize: Cache hit for ${cacheKey}`);

      optimalFontSize = cache.get(cacheKey);
      document.documentElement.style.setProperty(`--${face}-font-size`, `${optimalFontSize}px`);
    }
    else {
      //console.log(`memoizedFindOptimalFontSize: Cache miss for ${cacheKey}`);

      optimalFontSize = findOptimalFontSize(values, face, cardId);
      cache.set(cacheKey, optimalFontSize);
    }
    
    return optimalFontSize;
  };
})();
//memoizedFindOptimalFontSize = timed(memoizedFindOptimalFontSize);

export const useCalculateFontSize = (frontContentRef, backContentRef, cardRef) => {
  return useCallback((face, cardId) => {
    //console.time(`UNIQUE CALL END-${face}-${cardId}`);
    //console.log(`UNIQUE CALL START`)
    const contentRef = face === 'front' ? frontContentRef : backContentRef;
    if (contentRef.current && cardRef.current) {
      const fontSize = memoizedFindOptimalFontSize(contentRef, cardRef, face, cardId);
      return fontSize;
    }
    //console.timeEnd(`UNIQUE CALL END-${face}-${cardId}`);
  }, [frontContentRef, backContentRef, cardRef]);
};