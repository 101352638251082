export const creationTempCard = {
    front: String.raw`\text{This could be the front of your new card...}`,
    back: String.raw`\text{This could be the back of your new card...}`,
    _id: 'c-temp',
    createdAt: null,
    messages: [
        {
            role: "user",
            content: String.raw`Edit the template below to manually create your own flashcards:`,
        },
        {
            role: "assistant",
            content: String.raw`### Front: [latex]\text{Insert text here...}[/latex] ### Back: [latex]\text{Insert text here...}[/latex]`,
        }
    ],
    paramSearch: {
        front: { fontSize: null, cardWidth: null },
        back: { fontSize: null, cardWidth: null }
      }
};