// DeckChat.js
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MessageComponent from './MessageComponent';
import { useDeckContext } from './DeckContext';
import './DeckChat.css';

const MIN_TEXTAREA_HEIGHT = 35;

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => { ref.current = value; });
    return ref.current;
}

const DeckChat = ({ isOnMobile, isCollapsed }) => {
    const { deckId } = useParams();
    const {
        currentCardIndex,
        setCurrentCardIndex,
        combinedCards,
        errorMessage,
        setTempCard,
        setErrorMessage,
        currentDeck,
        makeAuthenticatedRequest,
        getAccessTokenSilently
    } = useDeckContext();

    const [userInput, setUserInput] = useState('');
    const [inputVisible, setInputVisible] = useState(isCollapsed || !isOnMobile);
    const prevIsCollapsed = usePrevious(isCollapsed);

    const messagesEndRef = useRef(null);
    const textAreaRef = useRef(null);

    // Extract complex expression into a memoized value
    const currentCardMessages = useMemo(() => combinedCards[currentCardIndex]?.messages || [], [combinedCards, currentCardIndex]);

    // Change deck-related state variables when deckId changes
    useEffect(() => {
        setUserInput('');
    }, [deckId]);

    const scrollChatToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!userInput.trim()) return;

        const inputMessages = combinedCards[currentCardIndex].messages.map((message) => ({
            role: message.role,
            content: message.content,
        }));

        inputMessages.push({ role: 'user', content: userInput });

        try {
            const response = await makeAuthenticatedRequest('/api/chat', 'POST', { messages: inputMessages }, getAccessTokenSilently);
            if (response && response.renderedLatex) {
                const isLatexNew = !currentDeck.cards.some(card => card.front === response.latexContent.front && card.back === response.latexContent.back);

                if (isLatexNew) {
                    const newTempCard = {
                        front: response.latexContent.front,
                        back: response.latexContent.back,
                        _id: (currentCardIndex > 0 ? combinedCards[currentCardIndex]._id : 'temp'),
                        createdAt: new Date(),
                        messages: [...inputMessages, { role: 'assistant', content: response.rawResponseString }],
                        paramSearch: {
                            front: { fontSize: null, cardWidth: null },
                            back: { fontSize: null, cardWidth: null }
                        }
                    };
                    // This will trigger card-state useEffect in DeckPage.js, so don't update same things twice causing race condition
                    setTempCard(newTempCard);
                    setCurrentCardIndex(0);
                }
                setUserInput('');
            }
        } catch (error) {
            setErrorMessage(error.message);
        }
    };

    const renderMessages = (messages) => { // FIXME: should we be returning JSX or storing it in a state variable?
        return messages.map((msg, index) => (
            <MessageComponent
                key={index}
                msg={msg}
                index={index}
            />
        ));
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            scrollChatToBottom();
        }, 100);
        return () => clearTimeout(timer);
    }, [currentCardMessages]);

    // One way delay of chat-form collapse
    useEffect(() => {
        // Only add delay when isCollapsed is going from false to true
        if (isCollapsed && !prevIsCollapsed) {
            const timer = setTimeout(() => { setInputVisible(isCollapsed || !isOnMobile); }, 50); // 50ms delay
            return () => clearTimeout(timer);
        } else {
            setInputVisible(isCollapsed || !isOnMobile);
        }
    }, [isCollapsed, isOnMobile, prevIsCollapsed]);

    // For multiline input form dynamic height
    React.useLayoutEffect(() => {
        if (textAreaRef.current) {
            // Reset height - important to shrink on delete
            textAreaRef.current.style.height = "inherit";
            // Set height
            textAreaRef.current.style.height = `${Math.max(
                textAreaRef.current.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        }
    }, [userInput]);

    return (
        <div className="deck-chat">
            <div className="chat-messages">
                {isOnMobile && <div ref={messagesEndRef} />}
                {/* FIXME: the below JSX component should likely be statefully created by a useEffect on each change of deckId or on each mount */}
                {isOnMobile
                    ? renderMessages([currentCardMessages].reverse())
                    : renderMessages(currentCardMessages)
                }
                {errorMessage && (
                    <div className="error-message">
                        {errorMessage}
                    </div>
                )}
                {!isOnMobile && <div ref={messagesEndRef} />}
            </div>
            {inputVisible && (
                <form className="chat-form" onSubmit={handleSubmit}>
                    <textarea
                        ref={textAreaRef}
                        value={userInput}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleSubmit(e);
                            }
                        }}
                        placeholder={currentCardIndex > 0 ? "Request changes..." : "Request a new card..."}
                        rows={1}
                        style={{
                            minHeight: 35,
                            resize: "none"
                        }}
                    />
                    <button type="submit">Go</button>
                </form>
            )}
        </div>
    );
};

export default DeckChat;