// src/components/NavBar.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useDeckContext } from './DeckContext';

import Submenu from './Submenu';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

import './NavBar.css';

const MOBILE_BREAKPOINT = 768; // pixels

const NavBar = ({ isCollapsed, setIsCollapsed }) => {
    const { decks, addDeck, updateDeck, deleteDeck } = useDeckContext();
    const [submenuVisible, setSubmenuVisible] = useState(false);
    const [openSubmenuId, setOpenSubmenuId] = useState(null);
    const [submenuPosition, setSubmenuPosition] = useState({ top: 0, left: 0 });
    const [renamingDeckId, setRenamingDeckId] = useState(null);
    const [isAddingDeck, setIsAddingDeck] = useState(false);
    const [newDeckName, setNewDeckName] = useState("");
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deleteConfirmationPosition, setDeleteConfirmationPosition] = useState({ top: 0, left: 0 });

    const deleteConfirmationRef = useRef(null);
    const navRef = useRef(null);
    const hamburgerRef = useRef(null);
    const submenuRef = useRef(null);
    const inputRef = useRef(null);
    const renameRef = useRef(null);

    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const location = useLocation();
    const navigate = useNavigate();

    // Function to determine if given link is active
    const isActive = (path) => location.pathname === path;

    const openSubmenu = (deckId, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setSubmenuPosition({
            top: rect.bottom + window.scrollY,
            left: rect.right + window.scrollX
        });
        setSubmenuVisible(true);
        setOpenSubmenuId(deckId);
        setIsCollapsed(false);
    };

    const toggleSubmenu = (deckId, event) => {
        if (openSubmenuId === deckId) {
            setSubmenuVisible(false);
            setOpenSubmenuId(null);
        } else {
            openSubmenu(deckId, event);
        }
    };

    const getDeleteConfirmationPosition = () => {
        if (submenuRef.current) {
            const submenuRect = submenuRef.current.getBoundingClientRect();
            return {
                top: submenuRect.bottom,
                left: submenuRect.left + (submenuRect.width / 2),
            };
        }
        return deleteConfirmationPosition;
    };

    const shareDeck = (deckId) => {
        // Define functionality to share deck
    };

    const renameDeck = async (deckId, newName) => {
        const updatedDeck = await updateDeck(deckId, { name: newName });
        if (updatedDeck) {
            console.log("Deck renamed successfully");
        } else {
            console.error("Error renaming deck");
        }
        setNewDeckName("");
    };

    const deleteDeckSubmenu = (deckId) => {
        setOpenSubmenuId(deckId);
        const position = getDeleteConfirmationPosition();
        setDeleteConfirmationPosition(position);
        setDeleteConfirmationVisible(true);
    };

    const confirmDeleteDeck = async (deckId) => {
        await deleteDeck(deckId);
        setDeleteConfirmationVisible(false);
        setSubmenuVisible(false);
    };

    const handleAddDeck = async () => {
        if (!newDeckName.trim()) return;
        const newDeck = await addDeck(newDeckName);
        setNewDeckName("");
        setIsAddingDeck(false);
        navigate(`/deck/${newDeck._id}`);   // Redirect to the new deck's page
    };

    const initiateAddDeck = () => {
        if (!isAuthenticated) {
            loginWithRedirect();
        } else {
            setIsAddingDeck(true);
        }
    };

    const handleAddDeckKeyBinds = (event) => {
        if (event.key === 'Enter') {
            handleAddDeck();
        } else if (event.key === 'Escape') {
            setIsAddingDeck(false);
        }
    };

    const initiateRenameDeck = (deckId) => {
        setSubmenuVisible(false);
        setRenamingDeckId(deckId);
        const currentDeck = decks.find(deck => deck._id === deckId);
        if (currentDeck) {
            setNewDeckName(currentDeck.name);
        }
    };

    const handleRenameKeyBinds = (event, deckId) => {
        if (event.key === 'Enter' && newDeckName.trim()) {
            renameDeck(deckId, newDeckName.trim());
            setRenamingDeckId(null);
        } else if (event.key === 'Escape') {
            setRenamingDeckId(null);
        }
    };

    // Handle click outside NavBar to collapse
    useEffect(() => {
        const handleClickOutsideNavBar = (event) => {
            // Check if the submenu is rendered. If it is, check if the click is outside it.
            const clickOutsideSubmenu = submenuRef.current ? !submenuRef.current.contains(event.target) : true;

            if (
                !isCollapsed &&
                window.innerWidth <= MOBILE_BREAKPOINT &&
                navRef.current &&
                !navRef.current.contains(event.target) &&
                hamburgerRef.current &&
                !hamburgerRef.current.contains(event.target) &&
                clickOutsideSubmenu // Include this check to handle both open and closed states of the submenu
            ) {
                setIsCollapsed(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideNavBar);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideNavBar);
        };
    }, [isCollapsed, setIsCollapsed, submenuRef]);

    // Detect clicks outside the two input fields to update AddingDeck or RenamingDeck state respectively
    useEffect(() => {
        const handleClickOutsideInputField = (event) => {
            // If the click is outside the input and isAddingDeck is true, set it to false
            if (isAddingDeck && inputRef.current && !inputRef.current.contains(event.target)) {
                setIsAddingDeck(false);
            } else if (renamingDeckId !== null && renameRef.current && !renameRef.current.contains(event.target)) {
                setRenamingDeckId(null);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutsideInputField);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideInputField);
        };
    }, [isAddingDeck, renamingDeckId]); // Only re-run if isAddingDeck changes

    // Handle click outside submenu to collapse
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                !event.target.closest('.submenu-toggle') &&
                (!submenuRef.current || !submenuRef.current.contains(event.target)) &&
                (!deleteConfirmationRef.current || !deleteConfirmationRef.current.contains(event.target))
            ) {
                setSubmenuVisible(false);
                setDeleteConfirmationVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`nav-wrapper ${isCollapsed ? 'collapsed' : ''}`}>
            <nav ref={navRef} className="App-nav">
                {/* Top Links */}
                <div className="nav-section top-link">
                    <Link className={`nav-item ${isActive('/') ? 'active-nav-item' : ''}`} to="/">
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <img src="/favicon_new_white.png" alt="Icon" style={{ marginRight: '5px', height: '1.1em' }} />
                            MyFlashPal
                        </span>
                    </Link>
                </div>

                {/* Middle Links */}
                <div className="nav-section user-dependent" onScroll={() => setSubmenuVisible(false)}>
                    <Link className={`nav-item ${isActive('/dashboard') ? 'active-nav-item' : ''}`} to="/dashboard">Dashboard</Link>

                    {/* List of User Decks */}
                    {decks.map((deck) => (
                        <div key={deck._id} className='deck-container'>
                            {renamingDeckId === deck._id ? (
                                <input
                                    ref={renameRef}
                                    className="deck-input"
                                    type="text"
                                    inputMode='search'
                                    value={newDeckName}
                                    onChange={(e) => setNewDeckName(e.target.value)}
                                    onKeyDown={(event) => handleRenameKeyBinds(event, deck._id)}
                                    autoFocus
                                />
                            ) : (
                                <Link className={`nav-item ${isActive(`/deck/${deck._id}`) ? 'active-nav-item' : ''}`} to={`/deck/${deck._id}`}>
                                    {deck.name}
                                    <button className="submenu-toggle" onClick={(e) => toggleSubmenu(deck._id, e)}>⋮</button>
                                </Link>
                            )}

                            {/* Submenu for Deck Labeled */}
                            {submenuVisible && openSubmenuId === deck._id && (
                                <Submenu ref={submenuRef} className="submenu" position={submenuPosition}>
                                    <button onClick={() => shareDeck(openSubmenuId)}>Share</button>
                                    <button onClick={() => initiateRenameDeck(openSubmenuId)}>Rename</button>
                                    <button style={{ color: "rgb(221, 82, 76)" }} onClick={() => deleteDeckSubmenu(openSubmenuId)}>Delete</button>
                                </Submenu>
                            )}
                            {deleteConfirmationVisible && openSubmenuId === deck._id && (
                                <Submenu ref={deleteConfirmationRef} className="submenu delete" position={deleteConfirmationPosition}>
                                    <p>Are you sure?</p>
                                    <button style={{ color: "rgb(221, 82, 76)" }} onClick={() => confirmDeleteDeck(openSubmenuId)}>Yes, Delete</button>
                                    <button onClick={() => {
                                        setDeleteConfirmationVisible(false);
                                        setSubmenuVisible(false);
                                    }}>Cancel</button>
                                </Submenu>
                            )}
                        </div>
                    ))}

                    {/* Add New Deck */}
                    {isAddingDeck ? (
                        <input
                            ref={inputRef}
                            className="deck-input"
                            type="text"
                            inputMode='search'
                            value={newDeckName}
                            onChange={(e) => setNewDeckName(e.target.value)}
                            onKeyDown={handleAddDeckKeyBinds}
                            autoFocus
                        />
                    ) : (
                        <button onClick={initiateAddDeck} className="nav-item">Add Deck</button>
                    )}
                </div>

                {/* Bottom Links */}
                <div className="nav-section bottom-link">
                    <Link className={`nav-item ${isActive('/settings') ? 'active-nav-item' : ''}`} to="/settings">Settings</Link>
                    {isAuthenticated ? <LogoutButton className="nav-item" /> : <LoginButton className="nav-item" />}
                </div>
            </nav>

            {/* Collapse Navbar Button */}
            <button ref={hamburgerRef} onClick={() => setIsCollapsed(!isCollapsed)} className="CollapseButton">
                <div className="hamburger-menu">
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </button>
        </div>
    );
};

export default NavBar;