// DeckLatex.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Submenu from './Submenu';
import { useDeckContext } from './DeckContext';
import { useCalculateFontSize } from '../utils/fontSizeUtils';

import './DeckLatex.css';

const DeckLatex = () => {
    const { deckId } = useParams();
    const {
        userStudying,
        studySessionStartTime,
        combinedCards,
        currentCardIndex,
        setCurrentCardIndex,
        tempCard,
        showFront,
        setShowFront,
        renderedLatex,
        setEditedFrontLatexContent,
        setEditedBackLatexContent,
        startFullDeckStudySession,
        getNextRepetitionDueDate,
        currentDeck,
        updateCardRepetition,
        addCard,
        deleteCard
    } = useDeckContext();

    const cardRef = useRef(null);
    const frontContentRef = useRef(null);
    const backContentRef = useRef(null);

    const deleteButtonRef = useRef(null);
    const submenuRef = useRef(null);

    const calculateFontSize = useCalculateFontSize(frontContentRef, backContentRef, cardRef);
    const [calculatingFontSize, setCalculatingFontSize] = useState(false);

    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deleteConfirmationPosition, setDeleteConfirmationPosition] = useState({ top: 0, left: 0 });

    const ActionButton = ({ currentCardIndex, tempCard, handleSaveCard, openSubmenu }) => {
        if (currentCardIndex === 0 && tempCard._id === 'c-temp') {  // creating card
            return (
                <button className="creation-pseudobutton">
                    Create a new Flashcard!
                </button>
            );
        } else if (currentCardIndex === 0 && tempCard._id !== 'c-temp') { // editing card
            return (
                <button onClick={handleSaveCard}>
                    Save Card
                </button>
            );
        } else if (currentCardIndex > 0) {  // viewing card
            return (
                <button
                    ref={deleteButtonRef}
                    className="delete-button"
                    onClick={openSubmenu}
                >
                    Delete Card
                </button>
            );
        }
        return null;
    };

    const getDeleteConfirmationPosition = () => {
        if (cardRef.current) {
            const cardRect = cardRef.current.getBoundingClientRect();
            return {
                top: cardRect.bottom,
                left: cardRect.left + (cardRect.width / 2),
            };
        }
        return deleteConfirmationPosition;
    };

    const openSubmenu = () => {
        const position = getDeleteConfirmationPosition();
        setDeleteConfirmationPosition(position);
        setDeleteConfirmationVisible(true);
    };

    // Function to adjust the scale of KaTeX content based on the container width
    const adjustKaTeXScale = useCallback(() => {
        if (calculatingFontSize) return;

        if (cardRef.current && combinedCards[currentCardIndex]) {
            const containerWidth = cardRef.current.clientWidth;
            const currParams = combinedCards[currentCardIndex].paramSearch;
            const currentFace = showFront ? 'front' : 'back';
            const scaleFactor = containerWidth / (currParams[currentFace].cardWidth || containerWidth);

            document.documentElement.style.setProperty(`--${currentFace}-scale-factor`, scaleFactor);
        }
    }, [combinedCards, currentCardIndex, showFront, calculatingFontSize]);

    const latestValuesRef = useRef();

    // Triggers during card creation or card editing, only for relevant face(s)
    useEffect(() => {
        latestValuesRef.current = {
            combinedCards,
            currentCardIndex
        };

        const observeContent = (contentRef, face, cardId) => {
            if (!contentRef.current) return () => { };

            const observer = new MutationObserver((mutations) => {
                for (let mutation of mutations) {
                    if (mutation.type === 'childList') {
                        const katexElement = contentRef.current.querySelector('.katex');
                        if (katexElement) {
                            observer.disconnect();

                            // Use ref values instead of closure values
                            const { combinedCards: latestCards, currentCardIndex: latestIndex } = latestValuesRef.current;
                            const currentParamSearch = latestCards[latestIndex].paramSearch;
                            if (!currentParamSearch[face] || currentParamSearch[face].fontSize == null) {
                                setCalculatingFontSize(true);
                                const newFontSize = calculateFontSize(face, cardId);
                                const newCardWidth = cardRef.current ? cardRef.current.clientWidth : null;

                                // Update using latest values
                                latestCards[latestIndex].paramSearch = {
                                    ...currentParamSearch,
                                    [face]: {
                                        fontSize: newFontSize,
                                        cardWidth: newCardWidth
                                    }
                                };
                                setCalculatingFontSize(false);
                                adjustKaTeXScale(); // Apply scale after calculations are complete
                            }
                            break;
                        }
                    }
                }
            });

            observer.observe(contentRef.current, { childList: true, subtree: true });
            return () => observer.disconnect();
        };

        const currentCard = combinedCards[currentCardIndex];
        const cardId = currentCard ? currentCard._id : 'temp';

        const frontObserver = observeContent(frontContentRef, 'front', cardId);
        const backObserver = observeContent(backContentRef, 'back', cardId);

        return () => {  // Cleanup function
            frontObserver();
            backObserver();
        };
    }, [currentCardIndex, calculateFontSize, combinedCards, frontContentRef, backContentRef, studySessionStartTime, adjustKaTeXScale]);

    // Set up ResizeObserver to listen for changes in the size of the latex-content element
    useEffect(() => {
        const latexContent = document.querySelector('.latex-content');
        if (!latexContent) return;

        const resizeObserver = new ResizeObserver(() => {
            if (!calculatingFontSize) {
                adjustKaTeXScale();
            }
        });

        resizeObserver.observe(latexContent);

        return () => { resizeObserver.disconnect(); };
    }, [adjustKaTeXScale, showFront, calculatingFontSize]);

    // Load paramSearch CSS Properties on currentCardIndex change. If the loaded values are null, then the above MutationObserver runs the paramSearch.
    useEffect(() => {
        if (combinedCards[currentCardIndex] && combinedCards[currentCardIndex].paramSearch) {
            const currParams = combinedCards[currentCardIndex].paramSearch;
            document.documentElement.style.setProperty('--front-font-size', `${currParams.front.fontSize}px`);
            document.documentElement.style.setProperty('--back-font-size', `${currParams.back.fontSize}px`);
        }
    }, [currentCardIndex, combinedCards]);

    const navigateCard = (direction) => {  // direction: 1 for forward, -1 for backward
        if (frontContentRef.current && backContentRef.current) {
            frontContentRef.current.classList.add('transitioning');
            backContentRef.current.classList.add('transitioning');
        }

        if (!showFront) flipCard(true);

        setCurrentCardIndex((prevIndex) =>
            (prevIndex + direction + combinedCards.length) % combinedCards.length
        );

        setEditedBackLatexContent(null);
        setEditedFrontLatexContent(null);

        setTimeout(() => {
            requestAnimationFrame(() => {
                if (frontContentRef.current && backContentRef.current) {
                    frontContentRef.current.classList.remove('transitioning');
                    backContentRef.current.classList.remove('transitioning');
                }
            });
        }, 250);
    };

    const goToNextCard = () => navigateCard(1);
    const goToPreviousCard = () => navigateCard(-1);

    const flipCard = useCallback((skipTransition = false) => {
        if (cardRef.current) {
            if (skipTransition) {

                cardRef.current.style.transition = 'transform 0s';
                setShowFront(prevShowFront => !prevShowFront);

                requestAnimationFrame(() => {
                    if (cardRef.current) {
                        cardRef.current.style.transition = 'transform 0.5s';
                    }
                });
            } else {
                setShowFront(prevShowFront => !prevShowFront);
            }
        }
    }, [setShowFront]);

    const handleSaveCard = async () => {
        const currentParamSearch = combinedCards[currentCardIndex].paramSearch;
        addCard(currentParamSearch);
    };

    const handleDeleteCard = async () => {
        await deleteCard(combinedCards[currentCardIndex]._id);
        setDeleteConfirmationVisible(false);
    };

    // Collapse delete-doublecheck submenu when click outside it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                deleteConfirmationVisible &&
                submenuRef.current &&
                !submenuRef.current.contains(event.target) &&
                deleteButtonRef.current &&
                !deleteButtonRef.current.contains(event.target)
            ) {
                setDeleteConfirmationVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [deleteConfirmationVisible]);

    // Clean transition on deck change also
    useEffect(() => {
        if (frontContentRef.current && backContentRef.current) {
            frontContentRef.current.classList.add('transitioning');
            backContentRef.current.classList.add('transitioning');

            //if (!showFront) flipCard(true);

            setTimeout(() => {
                requestAnimationFrame(() => {
                    if (frontContentRef.current && backContentRef.current) {
                        setTimeout(() => {
                            requestAnimationFrame(() => {
                                frontContentRef.current.classList.remove('transitioning');
                                backContentRef.current.classList.remove('transitioning');
                            });
                        }, 125);
                        if (!showFront) flipCard(true);
                    }
                });
            }, 125);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deckId]); // Only run when deckId changes

    const handleRecallResponse = async (responseQuality) => updateCardRepetition(combinedCards[currentCardIndex]._id, responseQuality);

    return (
        <div className="deck-latex">
            {(userStudying && combinedCards.length === 0) ? (
                <div className="full-deck-session-container">
                    <div className="full-deck-session-content">
                        <h2>No more cards left for today's session!</h2>
                        <p>Do you want to start a full deck study session?</p>
                        <button onClick={() => startFullDeckStudySession(currentDeck._id)}>Start Full Deck Session</button>
                    </div>
                </div>
            ) : (
                <div className="card-container">
                    <div
                        ref={cardRef}
                        className={`latex-content ${!showFront ? 'flipped' : ''} ${userStudying ? 'studying' : 'creating'}`}
                        onClick={() => { userStudying ? (showFront ? flipCard() : void (0)) : flipCard() }}
                    >
                        <div ref={frontContentRef} className="card-face card-front" dangerouslySetInnerHTML={{ __html: renderedLatex.front }} />
                        <div ref={backContentRef} className="card-face card-back" dangerouslySetInnerHTML={{ __html: renderedLatex.back }} />
                    </div>

                    {(userStudying) ? (
                        (showFront) ? (
                            <div className="study-progress">
                                <p>{combinedCards.length} cards left</p>
                                <button onClick={() => flipCard()}>Show Answer</button>
                            </div>
                        ) : (
                            <div className="recall-strength-input">
                                <h3>Rate your recall strength:</h3>
                                <h4>Current Interval: {getNextRepetitionDueDate(combinedCards[currentCardIndex]).toString()} days</h4>
                                <div className="recall-strength-buttons">
                                    <span className="recall-strength-buttons-group again">
                                        <span className="recall-strength-buttons-wrapper">
                                            <button onClick={() => handleRecallResponse(0)}>0</button>
                                            <button onClick={() => handleRecallResponse(1)}>1</button>
                                            <button onClick={() => handleRecallResponse(2)}>2</button>
                                            <button onClick={() => handleRecallResponse(3)}>3</button>
                                        </span>
                                        <span className="recall-strength-label">Again</span>
                                    </span>
                                    <span className="recall-strength-buttons-group later">
                                        <span className="recall-strength-buttons-wrapper">
                                            <button onClick={() => handleRecallResponse(4)}>4</button>
                                            <button onClick={() => handleRecallResponse(5)}>5</button>
                                        </span>
                                        <span className="recall-strength-label">Later</span>
                                    </span>
                                </div>
                            </div>
                        )
                    ) : null}
                </div>
            )}
            {!userStudying && (
                <div className="buttons-container">
                    {combinedCards.length > 1 && (
                        <>
                            <button onClick={goToPreviousCard}>Previous Card</button>
                            <ActionButton
                                currentCardIndex={currentCardIndex}
                                tempCard={tempCard}
                                handleSaveCard={handleSaveCard}
                                openSubmenu={openSubmenu}
                            />
                            <button onClick={goToNextCard}>Next Card</button>
                        </>
                    )}
                    {combinedCards.length <= 1 && (
                        <ActionButton
                            currentCardIndex={currentCardIndex}
                            tempCard={tempCard}
                            handleSaveCard={handleSaveCard}
                            openSubmenu={openSubmenu}
                        />
                    )}
                    {deleteConfirmationVisible && (
                        <Submenu ref={submenuRef} className="submenu-card" position={deleteConfirmationPosition}>
                            <p>Are you sure?</p>
                            <button style={{ color: "rgb(221, 82, 76)" }} onClick={handleDeleteCard}>Yes, Delete</button>
                            <button onClick={() => setDeleteConfirmationVisible(false)}>Cancel</button>
                        </Submenu>
                    )}
                </div>
            )}
        </div>
    );
};

export default DeckLatex;